/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
html {
  font-size: 16px;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: normal;
  color: #545454;
}

fieldset {
  border-width: 1px 0 0;
  border-style: solid;
  margin: 32px 0;
  padding: 0;
  border-color: rgba(0, 0, 0, 0.08);
}

legend {
  font-size: 12px;
  text-align: left;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 12px;
  text-transform: uppercase;
  padding: 0 8px 0 0;
  width: auto;
  color: #444444;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: normal;
}

a {
  color: #06b57e;
}
a:hover {
  color: #06a070;
}

.pointer {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.force-full-width {
  width: 100% !important;
}

.text-highlight {
  font-weight: 500;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.center {
  text-align: center !important;
}

.field-header {
  width: 85px;
}

.k-window {
  margin-top: 0;
  margin-bottom: 0;
}
.k-window.k-widget {
  line-height: 1.42857143;
}

/* Add default min-width for kendo-combobox (pending prescriptions) */
.min-w-280 {
  min-width: 280px;
}

/* Disabled */
.disabled-state {
  background: repeating-linear-gradient(135deg, transparent, transparent 6px, rgba(204, 216, 209, 0.1) 0, rgba(204, 216, 209, 0.15) 10px) rgba(204, 216, 209, 0.1);
}

.fa-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn-unavailable {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.msoap-unavailable * {
  cursor: not-allowed;
  opacity: 0.9;
}

.li-unavailable {
  opacity: 0.6 !important;
  cursor: not-allowed !important;
}

/*
** Margin **
 */
/* Small */
.margin-l-sm {
  margin-left: 8px !important;
}

.margin-b-sm {
  margin-bottom: 8px;
}

.margin-r-sm {
  margin-right: 8px;
}

.margin-t-sm {
  margin-top: 8px;
}

/* Medium */
.margin-t-md {
  margin-top: 16px;
}

.margin-b-md {
  margin-bottom: 16px;
}

.margin-r-md {
  margin-right: 16px;
}

.margin-l-md {
  margin-left: 16px;
}

.margin-t-b-md {
  margin: 16px 0;
}

/* Large */
.margin-r-lg {
  margin-right: 32px;
}

.margin-l-lg {
  margin-left: 32px;
}

.margin-t-lg {
  margin-top: 32px;
}

.margin-b-lg {
  margin-bottom: 32px;
}

/* Forcing Breaks */
.break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

/* Toast update */
.toast-update {
  background-color: #44444a;
  display: block;
}
.toast-update .toast-message {
  font-size: 14px;
}
.toast-update .action-btn {
  color: #31d5ad;
  font-size: 14px;
  text-decoration: underline;
}

.hidden-pdf-export,
.invisible-hack {
  position: absolute;
  max-height: 0px;
  max-width: 0px;
  overflow: hidden;
}

#toast-container > div {
  opacity: 1;
}

.notification-banner-container a {
  color: #06b57e;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

.type-list-management .k-upload .k-upload-button {
  margin: 0;
  border: none;
}

.viewer .k-tabstrip-top > .k-tabstrip-items {
  display: flex;
  flex-wrap: wrap;
}
.viewer .k-tabstrip-top > .k-tabstrip-items .k-link {
  font-size: 12px;
  padding: 5px 8px;
  line-height: 14px;
}

.summary-report .k-window {
  overflow: auto;
}

.progress-container {
  /* Add striped progress bar */
}
.progress-container .bar {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.ms-alert {
  padding: 2px 8px;
  border-radius: 2px;
}
.ms-alert > div {
  display: flex;
  align-items: center;
}
.ms-alert--warning {
  color: #ff9333;
  border: 1px solid #ff9333;
}
.ms-alert--success {
  color: #06b57e;
  border: 1px solid #06b57e;
}
.ms-alert--error {
  color: #d51923;
  border: 1px solid #d51923;
}
.ms-alert__ic {
  margin-right: 6px;
}

.k-radio + .k-radio-label {
  display: inline-flex;
}

.toast-lg-top-md {
  width: 750px !important;
  top: 220px !important;
  padding-top: 25px !important;
}

.tox.tox-silver-sink.tox-tinymce-aux {
  z-index: 50000 !important;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}